import axios from 'axios';

axios.defaults.timeout = 50000;
// 创建axios实例，并设置默认的header
const axiosInstance = axios.create({
    baseURL: 'https://api.yzcwh.cn/user',  // 替换为你的实际API地址
    timeout: 100000,  // 请求超时时间
});

// 请求拦截器（可选）
axiosInstance.interceptors.request.use(
    config => {
        // 可以在这里对每个请求进行进一步的配置
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器（可选）
axiosInstance.interceptors.response.use(
    response => {
        // 处理响应数据
        return response.data;
    },
    error => {
        // 处理响应错误
        return Promise.reject(error);
    }
);

// 封装get请求
export function getRequest(url, params = {}) {
    return axiosInstance.get(url, { params });
}

// 封装post请求
export function postRequest(url, data = {}) {
    return axiosInstance.post(url, data);
}

// 封装put请求
export function putRequest(url, data = {}) {
    return axiosInstance.put(url, data);
}

// 封装delete请求
export function deleteRequest(url, params = {}) {
    return axiosInstance.delete(url, { params });
}
