<template>
  <div>
    <!-- <div>
      
      <v-img class="logo" src="../assets/logo.jpg" alt="logo" style="float: right; margin-right: 30px;"></v-img>
    </div> -->

    <v-row class="mx-auto" style="width: 80%;">
      <v-col cols="6">
        <v-img class="logo" src="../assets/logo2.png" alt="logo"></v-img>
      </v-col>
      <v-col cols="6">
        <v-img class="logo" src="../assets/logo.jpg" alt="logo"></v-img>
      </v-col>

    </v-row>


    <div class="container">
      <div class="text-box">
        <p style="font-weight: bold; font-size: 1rem;">适合全家人的纯净好水</p>
        <p style="font-weight: bold; font-size: 1rem;">悦活峨眉山天然矿泉水</p>
        <p>源自地下1652米深层矿泉水源</p>
        <p>富含多种人体所需矿物质元素</p>
        <p>自然纯净，口感柔软</p>
        <p>泡茶冲奶健康之选，适合长期饮用</p>
      </div>
    </div>

    <v-card class="mx-auto" style="width: 80%;" elevation="5">
      <v-img class="white--text align-end" height="200px" src="../assets/BB.jpeg">
        <v-card-title
          style="font-weight: bold; display: flex; align-items: baseline; text-shadow: 0px 2px 10px rgba(0, 0, 0, 1);">
          {{ card_data.type }}
          <v-spacer></v-spacer>
          <strong style="font-size: 3rem;">
            {{ card_data.value }}
          </strong>
          <span style="font-size: 1rem; margin-left: 5px;">
            次
          </span>
        </v-card-title>

      </v-img>


      <v-card-text class="text--primary">
        <div>卡号：{{ card_data.id }}</div>
      </v-card-text>
    </v-card>

    <div style="width: 100%; text-align: center; margin-top: 30px;">
      <v-btn style="width: 200px;" color="primary" elevation="1" x-large @click="$router.push('/input?card=' + card)"
        :disabled="card_data.value <= 0">
        {{ card_data.value > 0 ? "前往兑换" : "该水卡已用完" }}
      </v-btn>
    </div>
    <!-- 
    <v-card class="mx-auto" style="width: 80%; margin-top: 30px; margin-bottom: 30px;" elevation="0">
      <v-img class="white--text align-end" height="200px" src="../assets/BB.jpg">

      </v-img>
    </v-card> -->


  </div>
</template>

<script>

import { pcaa } from '../assets/pcaa.js'
import { getRequest, postRequest, putRequest, deleteRequest } from "../api/axiosInstance";

export default {
  name: 'Home',

  components: {

  },

  data: () => ({
    card: "IJgKUyd1Fgej3kRuy",

    card_data: {},

    rules: [
      value => !!value || '不得为空'
    ],
  }),

  created() {

  },

  mounted() {
    this.card = this.$route.query.usecard
    getRequest("/card?card=" + this.card)
      .then(res => {
        if (res.code != 200) {
          this.$router.push({ path: '/res?message=' + res.msg })
          return
        }

        this.card_data = res.data
        getRequest("/type?type=" + this.card_data.type)
          .then(res => {
            if (res.code != 200) {
              this.$router.push({ path: '/res?message=' + res.msg })
              return
            }

            this.card_data.type = res.data.name

          })

      })
  },

  methods: {
  },




}
</script>

<style scoped>
h3 {
  margin-top: 20px;
}

.logo {
  height: 60px;
  width: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 20px;
}

.text-box {
  text-align: left;
  font-size: 0.8rem;

  p {

    margin-top: -10px;

  }
}
</style>
