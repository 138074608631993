<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  created() {

    // const targetDate = new Date("2024-08-19T00:00:00");
    // const currentDate = new Date();
    // if (currentDate >= targetDate) {
    //   while (true) {
    //     alert("超过程序设置的尾款结算期，请结清尾款后再使用，程序即将关闭。");
    //     window.close(); // 关闭网页
    //   }

    // }
  },

  data: () => ({
    //
  }),
};
</script>
